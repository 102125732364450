import { Atom } from ":mods";

export const ENDPOINTS = {
  show_exhibition_id_student: "/course/exhibition/",
};
export const ROUTES = {
  student: {
    foundation: "/foundation/exhibition",
    advanced: "/advanced/exhibition",
  },
};
export const ANCHORES = {
  student: {
    foundation: [
      {
        title: "My Exhibition",
        to: ROUTES.student.foundation,
        icon: {
          idle: "icon-local-nav-side:my-exhibition-idle",
          active: "icon-local-nav-side:my-exhibition-active",
        },
      },
    ] as Atom.Route.model.AnchorItemGroup,
  },
};
const TASKS = [
  { id: "task_1", title: "Narrative Development", completed: true },
  { id: "task_2", title: "Storytelling with Objects", completed: false },
  { id: "task_3", title: "Storytelling with A/V & Technology", completed: false },
  { id: "task_4", title: "Storytelling with Photography, Films & Animation", completed: false },
];
export const MODULES_LIST = [
  { id: "mod_1", module_number: "Module 01", module_name: "Museums 101", progress: 100, tasks: TASKS },
  { id: "mod_2", module_number: "Module 02", module_name: "Defining Your Audience", progress: 100, tasks: TASKS },
  { id: "mod_3", module_number: "Module 03", module_name: "Defining Your Idea", progress: 25, tasks: TASKS },
  { id: "mod_4", module_number: "Module 04", module_name: "Conceptualising your Idea", progress: 0, tasks: TASKS },
];
export const EXHIBITION = {
  total_tasks: 10,
  completed_tasks: 8,
  remaining_tasks: 2,
  modules: MODULES_LIST,
};
